import React, { useContext } from 'react'
import { Context } from '../App';

function Modal({data}) {
    const { showModal, setShowModal} = useContext(Context);
    console.log(data);
    const json = [
        "Help spread the word by sharing this article on your social media!",
        "Don't keep this valuable information to yourself, share it on your social media!",
        "Want to help others learn about this topic? Share it on your social media channels.",
        "Make sure your friends and followers are in the know by sharing this article",
        "This article is too good not to share, post it on your social media!",
        "Want to be the one who keeps your friends informed? Share this article on social media.",
        "Be a social media influencer and share this article with your followers",,
        "Want to make an impact? Share this article on your social media and make a difference.",
        "Sharing is caring! Help others stay informed by sharing this article on your social media.",
        "This article is too good not to share. Help others discover it by posting it on your social media.",
        "Don't miss out on the conversation! Share this article on your social media and join in.",
        "This article is a must-read. Share it with your friends and followers on social media.",
        "Want to be the first to know? Share this article on your social media and stay ahead of the curve.",
        "Help others stay informed by sharing this article on your social media.",
        "This article is too good not to share. Help others discover it by posting it on your social media.",
        "Don't miss out on the conversation! Share this article on your social media and join in.",
        "This article is a must-read. Share it with your friends and followers on social media.",
        "Want to be the first to know? Share this article on your social media and stay ahead of the curve.",
        "Be a part of the conversation, Share this article on your social media.",
        "Sharing is the best way to support this information. Share it on your social media.",
        ];
    const randomCaption =()=>{
        return Math.floor(Math.random() * json.length);
    };
    const close=()=>{
        setShowModal(false);
    };
  return (
    <div>
<div id="popup-modal" tabindex="-1"  class="fixed top-0 left-0 right-0  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full">
    <div class="relative w-full h-full max-w-md md:h-auto">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <button onClick={close} type="button" class="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" data-modal-hide="popup-modal">
                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                <span class="sr-only">Close modal</span>
            </button>

            <div class="p-6 text-center">
                <h3 class="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">{json[randomCaption()]}</h3>
                <div class="flex justify-center space-x-2">

  <a href={`instagram://camera?caption=${data.caption}&path=${data.imageHeader}`} role="button">
    {/* <!-- Instagram --> */}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-7 h-7" style={{color:"#c13584"}}>
        <path fill="currentColor" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
  </a>
  
  <a href={`https://twitter.com/intent/tweet?text=${data.caption}&media=${data.imageHeader}`}>
    {/* <!-- Twitter --> */}
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-7 h-7" style={{color:"#1da1f2"}}>
        <path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
  </a>

</div>
             </div>
        </div>
    </div>
</div>


    </div>
  )
}

export default Modal