import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import Post from './Pages/Post';
import Blogs from './Pages/Blogs';
import { createContext, useState } from 'react';
export const Context = createContext(null);


function App() {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <Context.Provider value={{showModal, setShowModal}}>
      <BrowserRouter>
      <Routes>
        <Route path='/post/:blog_id' element={<Post/>} />
        <Route  path='/' exact element={<Blogs/>}/>
      </Routes>
      </BrowserRouter>
      </Context.Provider>
    </div>
  );
}

export default App;
