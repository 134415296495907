import React, { useEffect, useState } from 'react'
import { AxiosConnect } from '../AxiosConnect';

function Blogs() {
    const [blogs,setBlogs] = useState(null);
    const getBlogs = async ()=>{
        const {data} = await AxiosConnect.get("/all-blogs");
        if((data.data).length > 0){
            setBlogs(data.data);
        }else{
            console.log("Noblogs found");
            alert("Noblogs found");
        }
    };
    const [category,setCategory]= useState(null);

    const [filteredData, setFilteredData] = useState([]);
    const [listOfCategory,setListOfcategory] = useState([]);
    useEffect(()=>{
        async function getListOfCategories(){
            const {data} = await AxiosConnect.get("/categories");
            console.log(data);
            setListOfcategory(data.data)
        };
        getListOfCategories();
    },[]);
    useEffect(() => {
      if (!category || category == null) {
        setFilteredData(blogs);
      } else {
        setFilteredData(blogs.filter(item => item.category === category));
      }
    }, [blogs, category]);

    const changeCat=(value)=>{
        setCategory(value);
    }
    useEffect(()=>{
        getBlogs();
    },[])
  return (
    <body class="bg-white font-family-karla">

    <nav style={{background:"#612E35"}} class="w-full py-4  shadow">
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between">

            <nav>
                <ul class="flex items-center justify-between font-bold text-sm text-white uppercase no-underline">
                    <li><a class="hover:text-gray-200 hover:underline px-4" href="https://axgura.com">Axgura</a></li>
                    <li><a class="hover:text-gray-200 hover:underline px-4" href="https://shop.axgura.com">Shop</a></li>
                 </ul>
            </nav>

            <div class="flex items-center text-lg no-underline text-white pr-6">
                <a class="pl-6" href="https://www.instagram.com/axgura">
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="pl-6" href="https://twitter.com/axgura">
                    <i class="fab fa-twitter"></i>
                </a>
                {/* <a class="pl-6" href="#">
                    <i class="fab fa-linkedin"></i>
                </a> */}
            </div>
        </div>

    </nav>

    <header class="w-full container mx-auto">
        <div class="flex flex-col items-center py-12">
            <a class="font-bold text-gray-800 uppercase hover:text-gray-700 text-5xl" href="#">
                Axgura Blog
            </a>
            <p class="text-lg text-center text-gray-600">
                 Axgura blog is a platform for showcasing the innovative products and inventions created by the company. 
            </p>
        </div>
    </header>

    <nav class="w-full py-4 border-t border-b bg-gray-100" >
        <div class="block sm:hidden">
            <a
                href="#"
                class="block md:hidden text-base font-bold uppercase text-center flex justify-center items-center"
            >
                Topics <i class="fas ml-2"></i>
            </a>
        </div>
        <div class="w-full flex-grow">
            <div class="w-full container mx-auto flex flex-col sm:flex-row items-center justify-center text-sm font-bold uppercase mt-0 px-6 py-2">
                {
                    listOfCategory.length > 0 && listOfCategory.map(x=>
                        <a onClick={()=>changeCat(x.value)} class="hover:bg-gray-400 rounded py-2 px-4 mx-2">{x.name}</a>
                        )
                }
             </div>
        </div>
    </nav>


    <div class="container mx-auto flex flex-wrap py-6">

        <section class="w-full md:w-2/3 flex flex-col items-center px-3">

            {blogs && blogs !== null && filteredData && filteredData.map(blog=>
            <article key={blog.blog_id} class="flex flex-col shadow my-4">
                <a href="#" class="hover:opacity-75">
                    <img src={blog.imageHeader} />
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" style={{color:"#612E35"}} class="text-blue-700 text-sm font-bold uppercase pb-4">Technology</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">{blog.topic}</a>
                    <p href="#" class="text-sm pb-3">
                        By <a href="#" class="font-semibold hover:text-gray-800">{blog.author}</a>, Published on {blog.uploaded}
                    </p>
                    <a href="#" class="pb-6">{blog.description}</a>
                    <a href={`post/${blog.blog_id}`} class="uppercase text-gray-800 hover:text-black">Continue Reading <i class="fas fa-arrow-right"></i></a>
                </div>
            </article>)}

            {/* <article class="flex flex-col shadow my-4">
                <a href="#" class="hover:opacity-75">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=2" />
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Automotive, Finance</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">Lorem Ipsum Dolor Sit Amet Dolor Sit Amet</a>
                    <p href="#" class="text-sm pb-3">
                        By <a href="#" class="font-semibold hover:text-gray-800">David Grzyb</a>, Published on January 12th, 2020
                    </p>
                    <a href="#" class="pb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis porta dui. Ut eu iaculis massa. Sed ornare ligula lacus, quis iaculis dui porta volutpat. In sit amet posuere magna..</a>
                    <a href="#" class="uppercase text-gray-800 hover:text-black">Continue Reading <i class="fas fa-arrow-right"></i></a>
                </div>
            </article>

            <article class="flex flex-col shadow my-4">
                <a href="#" class="hover:opacity-75">
                    <img src="https://source.unsplash.com/collection/1346951/1000x500?sig=3"/>
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" class="text-blue-700 text-sm font-bold uppercase pb-4">Sports</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">Lorem Ipsum Dolor Sit Amet Dolor Sit Amet</a>
                    <p href="#" class="text-sm pb-3">
                        By <a href="#" class="font-semibold hover:text-gray-800">David Grzyb</a>, Published on October 22nd, 2019
                    </p>
                    <a href="#" class="pb-6">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus quis porta dui. Ut eu iaculis massa. Sed ornare ligula lacus, quis iaculis dui porta volutpat. In sit amet posuere magna..</a>
                    <a href="#" class="uppercase text-gray-800 hover:text-black">Continue Reading <i class="fas fa-arrow-right"></i></a>
                </div>
            </article> */}

            {/* <div class="flex items-center py-8">
                <a href="#" style={{background:"#612E35"}} class="h-10 w-10 bg-blue-800 hover:bg-blue-600 font-semibold text-white text-sm flex items-center justify-center">1</a>
                <a href="#" style={{background:"#612E35"}} class="h-10 w-10 font-semibold text-gray-800 hover:bg-blue-600 hover:text-white text-sm flex items-center justify-center">2</a>
                <a href="#" class="h-10 w-10 font-semibold text-gray-800 hover:text-gray-900 text-sm flex items-center justify-center ml-3">Next <i class="fas fa-arrow-right ml-2"></i></a>
            </div> */}

        </section>

        {/* <aside class="w-full md:w-1/3 flex flex-col items-center px-3">

            <div class="w-full bg-white shadow flex flex-col my-4 p-6">
                <p class="text-xl font-semibold pb-5">About Us</p>
                <p class="pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis est eu odio sagittis tristique. Vestibulum ut finibus leo. In hac habitasse platea dictumst.</p>
                <a href="#" class="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4">
                    Get to know us
                </a>
            </div>

            <div class="w-full bg-white shadow flex flex-col my-4 p-6">
                <p class="text-xl font-semibold pb-5">Instagram</p>
                <div class="grid grid-cols-3 gap-3">
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=1" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=2" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=3" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=4" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=5" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=6" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=7" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=8" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=9" />
                </div>
                <a href="#" class="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6">
                    <i class="fab fa-instagram mr-2"></i> Follow @dgrzyb
                </a>
            </div>

        </aside> */}

    </div>

    <footer class="w-full border-t bg-white pb-12">
        {/* <div
            class="relative w-full flex items-center invisible md:visible md:pb-12"
            x-data="getCarouselData()"
        >
            <button
                class="absolute bg-blue-800 hover:bg-blue-700 text-white text-2xl font-bold hover:shadow rounded-full w-16 h-16 ml-12"
                >
                &#8592;
            </button>
            <template x-for="image in images.slice(currentIndex, currentIndex + 6)" >
                <img class="w-1/6 hover:opacity-75" />
            </template>
            <button
                class="absolute right-0 bg-blue-800 hover:bg-blue-700 text-white text-2xl font-bold hover:shadow rounded-full w-16 h-16 mr-12"
                >
                &#8594;
            </button>
        </div> */}
        <div class="w-full container mx-auto flex flex-col items-center">
            <div class="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6">
                <a href="#" class="uppercase px-3">About Us</a>
                <a href="#" class="uppercase px-3">Privacy Policy</a>
                <a href="#" class="uppercase px-3">Terms & Conditions</a>
                <a href="#" class="uppercase px-3">Contact Us</a>
            </div>
            <div class="uppercase pb-6">&copy; blog.axgura.com</div>
        </div>
    </footer>

</body>
  )
}

export default Blogs