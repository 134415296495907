import React, { useContext, useEffect, useState } from 'react'
import { AxiosConnect } from '../AxiosConnect';
import { useParams } from 'react-router-dom';
import Modal from './Modal';
import { Context } from '../App';

const useModal = () => {
    const { showModal, setShowModal } = useContext(Context);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowModal(true);
            console.log("done!");
        }, 10000);
        return () => clearTimeout(timeoutId);
    }, []);

    return showModal;
}


function Post() {
    const {blog_id} = useParams();
    const [details,setDetails] = useState(null);
    const [nextPages,setNextPages] = useState(null);
    const showModal = useModal();
    const getBlogDetails =async()=>{
        console.log(blog_id);
        if(blog_id.length > 4){
            const {data} = await AxiosConnect.get(`/get-details/${blog_id}`);
            if(data.data) {
                setDetails(data.data);
                setNextPages({...nextPages,firstBlog:data.firstBlog,
                    secondBlog:data.secondBlog});
            }else{
                console.log("No data found");
                console.log(data.data);
            }
        }else{
            console.log("Blog ID not found");
        }
       
    };
    useEffect(()=>{
        getBlogDetails();
    },[]);

  return (
    <body class="bg-white font-family-karla">
    <nav style={{background:"#612E35"}} class="w-full py-4 bg-blue-800 shadow">
        <div class="w-full container mx-auto flex flex-wrap items-center justify-between">

            <nav>
                <ul class="flex items-center justify-between font-bold text-sm text-white uppercase no-underline">
                    <li><a class="hover:text-gray-200 hover:underline px-4" href="https://axgura.com">Axgura</a></li>
                    <li><a class="hover:text-gray-200 hover:underline px-4" href="https://shop.axgura.com">Shop</a></li>
                </ul>
            </nav>

            <div class="flex items-center text-lg no-underline text-white pr-6">
                <a class="pl-6" href="https://www.instagram.com/axgura">
                    <i class="fab fa-instagram"></i>
                </a>
                <a class="pl-6" href="https://twitter.com/axgura">
                    <i class="fab fa-twitter"></i>
                </a>
                {/* <a class="pl-6" href="#">
                    <i class="fab fa-linkedin"></i>
                </a> */}
            </div>
        </div>

    </nav>
        
    <div class="container mx-auto flex flex-wrap py-6">

        <section class="w-full md:w-2/3 flex flex-col items-center px-3">

            {
                details && details !== null ?
                <article key={details.blog_id} class="flex flex-col shadow my-4">
                <a href="#" class="hover:opacity-75">
                    <img src={details.imageHeader} />
                </a>
                <div class="bg-white flex flex-col justify-start p-6">
                    <a href="#" style={{color:"#612E35"}} class=" text-sm font-bold uppercase pb-4">Technology</a>
                    <a href="#" class="text-3xl font-bold hover:text-gray-700 pb-4">{details.topic}</a>
                    <p href="#" class="text-sm pb-8">
                        By <a href="#" class="font-semibold hover:text-gray-800">{details.author}</a>, Published on {details.uploaded}
                    </p>
                    <h1 class="text-2xl font-bold pb-3">Introduction</h1>
                    <p class="pb-3">{details.description}</p>
                    <h1 class="text-2xl font-bold pb-3">Content</h1>
                    {
                        details.content?
                        (JSON.parse(details.content)).length > 0 && (JSON.parse(details.content)).map(x=>
                            <p key={x} class="pb-3">
                            {x}
                        </p>) :<p class="pb-3">
                            {details.content}
                        </p>
                    }
                    {/* <p class="pb-3">Sed sagittis odio a volutpat feugiat. Cras aliquam varius justo, a rhoncus ante bibendum id. Nulla maximus nisl sed enim maximus, ut dictum lectus hendrerit. Fusce venenatis tincidunt eros. Phasellus quis augue vulputate ipsum pellentesque fringilla. Morbi nec tempor felis. Maecenas sollicitudin pellentesque dui, sit amet scelerisque mauris elementum nec. Cras ante metus, mattis in malesuada in, fermentum a nunc. Suspendisse potenti. Sed tempor lacus sed commodo dignissim. Quisque dictum, dolor auctor iaculis cursus, ipsum urna porttitor ex, sed consequat nisi tellus eget ante. Duis molestie mollis eros, eu sollicitudin mauris lobortis quis.</p>
                    <p class="pb-3">Vivamus sed neque nec massa scelerisque imperdiet eget id sapien. Fusce elementum mi id malesuada luctus. Proin quis lorem id leo porta interdum non ac nisl. Integer nulla sem, ultrices sed neque eget, blandit condimentum metus. Aliquam eget malesuada sapien. Curabitur aliquet orci sit amet ex tincidunt convallis. Mauris urna mi, consequat mattis mollis a, dignissim eget sem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Nam facilisis sem diam, viverra consequat metus consequat vel. Cras a mi eu ex luctus malesuada quis eu ante. Aliquam erat volutpat.</p>
                    */}
                    <h1 class="text-2xl font-bold pb-3">Conclusion</h1>
                    <p class="pb-3">{JSON.parse(details.content)[(JSON.parse(details.content)).length-2]}</p>
                </div>
            </article>:<article></article>
            }
            {
                nextPages && nextPages !== null &&
                <div class="w-full flex pt-6">
                <a href={`${nextPages.firstBlog.link}`} class="w-1/2 bg-white shadow hover:shadow-md text-left p-6">
                    <p  style={{color:"#612E35"}} class="text-lg text-blue-800 font-bold flex items-center"><i class="fas fa-arrow-left pr-1"></i> Previous</p>
                    <p class="pt-2">{nextPages.firstBlog.name}</p>
                </a>
                <a href={`${nextPages.secondBlog.link}`} class="w-1/2 bg-white shadow hover:shadow-md text-right p-6">
                    <p style={{color:"#612E35"}} class="text-lg text-blue-800 font-bold flex items-center justify-end">Next <i class="fas fa-arrow-right pl-1"></i></p>
                    <p class="pt-2">{nextPages.firstBlog.name}</p>
                </a>
            </div>
             }

            {/* <div class="w-full flex flex-col text-center md:text-left md:flex-row shadow bg-white mt-10 mb-10 p-6">
                <div class="w-full md:w-1/5 flex justify-center md:justify-start pb-4">
                    <img src="https://source.unsplash.com/collection/1346951/150x150?sig=1" class="rounded-full shadow h-32 w-32" />
                </div>
                <div class="flex-1 flex flex-col justify-center md:justify-start">
                    <p class="font-semibold text-2xl">David</p>
                    <p class="pt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur vel neque non libero suscipit suscipit eu eu urna.</p>
                    <div class="flex items-center justify-center md:justify-start text-2xl no-underline text-blue-800 pt-4">
                        <a class="" href="#">
                            <i class="fab fa-facebook"></i>
                        </a>
                        <a class="pl-4" href="#">
                            <i class="fab fa-instagram"></i>
                        </a>
                        <a class="pl-4" href="#">
                            <i class="fab fa-twitter"></i>
                        </a>
                        <a class="pl-4" href="#">
                            <i class="fab fa-linkedin"></i>
                        </a>
                    </div>
                </div>
            </div> */}

        </section>

        {/* <aside class="w-full md:w-1/3 flex flex-col items-center px-3">

            <div class="w-full bg-white shadow flex flex-col my-4 p-6">
                <p class="text-xl font-semibold pb-5">About Us</p>
                <p class="pb-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas mattis est eu odio sagittis tristique. Vestibulum ut finibus leo. In hac habitasse platea dictumst.</p>
                <a href="#" class="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-4">
                    Get to know us
                </a>
            </div>

            <div class="w-full bg-white shadow flex flex-col my-4 p-6">
                <p class="text-xl font-semibold pb-5">Instagram</p>
                <div class="grid grid-cols-3 gap-3">
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=1" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=2" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=3" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=4" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=5" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=6" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=7" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=8" />
                    <img class="hover:opacity-75" src="https://source.unsplash.com/collection/1346951/150x150?sig=9" />
                </div>
                <a href="#" class="w-full bg-blue-800 text-white font-bold text-sm uppercase rounded hover:bg-blue-700 flex items-center justify-center px-2 py-3 mt-6">
                    <i class="fab fa-instagram mr-2"></i> Follow @dgrzyb
                </a>
            </div>

        </aside> */}

    </div>
    <div>
            {showModal && details && details !== null && <div class=""><Modal 
                data={details}
            /></div>}
        </div>
    <footer class="w-full border-t bg-white pb-12">
        {/* <div
            class="relative w-full flex items-center invisible md:visible md:pb-12"
            x-data="getCarouselData()"
        >
            <button
                class="absolute bg-blue-800 hover:bg-blue-700 text-white text-2xl font-bold hover:shadow rounded-full w-16 h-16 ml-12">
                &#8592;
            </button>
            <template x-for="image in images.slice(currentIndex, currentIndex + 6)" >
                <img class="w-1/6 hover:opacity-75" src="image" />
            </template>
            <button
                class="absolute right-0 bg-blue-800 hover:bg-blue-700 text-white text-2xl font-bold hover:shadow rounded-full w-16 h-16 mr-12">
                &#8594;
            </button>
        </div> */}
        <div class="w-full container mx-auto flex flex-col items-center">
            <div class="flex flex-col md:flex-row text-center md:text-left md:justify-between py-6">
                <a href="#" class="uppercase px-3">About Us</a>
                <a href="#" class="uppercase px-3">Privacy Policy</a>
                <a href="#" class="uppercase px-3">Terms & Conditions</a>
                <a href="#" class="uppercase px-3">Contact Us</a>
            </div>
            <div class="uppercase pb-6">&copy; blog.axgura.com</div>
        </div>
    </footer>

  

</body>
  )
}

export default Post